<template>
  <div class="page">
    <section>
      <span class="title">选择时间</span>
      <div class="time">
        <div class="block">
          <span class="demonstration"
            >起始日期时刻为 12:00:00，结束日期时刻为 08:00:00</span
          >
          <el-date-picker
            v-model="time"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['08:00:00', '12:00:00']"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd&HH:mm"
            :picker-options="pickerOptions"
            :append-to-body="false"
          >
          </el-date-picker>
        </div>
      </div>
      <el-button
        class="button"
        :loading="isLogin"
        type="primary"
        @click="jump"
        round
        >提交信息</el-button
      >
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLogin: false,
      time: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 1 * 24 * 60 * 60 * 1000 < Date.now();
        },
      },
    };
  },
  mounted() {},
  methods: {
    async jump() {
      if (!this.time) {
        this.$message({ message: "请选择时间", type: "error" });
      } else {
        let data = {
          questionnaire_id: this.$route.query.id,
          user_source: 3,
          type: 1,
          start_date: this.time[0].split("&")[0],
          end_date: this.time[1].split("&")[0],
          start_time: this.time[0].split("&")[1],
          end_time: this.time[1].split("&")[1],
        };
        this.isLogin = true;
        let res = await this.$researchApi.addQuestionnaire(data);
        this.isLogin = false;
        if (res.code == 200) {
          this.xin.goUrl("/research-interview-successfulParticipation");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  background: #f2f2f2;
  > section {
    display: flex;
    flex-direction: column;
    > .title {
      font-size: 18px;
      color: #333333;
      margin: 30px 0 22px 0;
      font-weight: bold;
    }
    > .time {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1200px;
      height: 515px;
      background: #ffffff;
      margin-bottom: 30px;
      > .block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        > .demonstration {
          font-size: 17px;
          color: #8f8f8f;
          margin-bottom: 14px;
        }
      }
    }
    > .button {
      cursor: pointer;
      width: 384px;
      background: $--themeColor;
      font-size: 15px;
      color: #ffffff;
      margin: 0 auto;
    }
  }
}
</style>